@tailwind components;
@tailwind base;
@tailwind utilities;

@layer base {
  :root {
    --hubla-primary: 75 65% 48%;

    --background: 0 0% 100%;
    --container-background: 0 0% 98%;

    --foreground: 240 10% 3.9%;

    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;

    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;

    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;

    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;

    --primary: 240 5.9% 10%;
    --primary-foreground: 0 0% 98%;

    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;

    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 240 5% 64.9%;

    --radius: 0.5rem;

    --chart-1: 75 65% 49%;
    --chart-2: 75 41% 89%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;

    --sidebar-background: 240 5% 96%;
    --sidebar-foreground: 240 5.3% 26.1%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 240 4.8% 95.9%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }

  .dark {
    --hubla-primary: 75 65% 48%;

    --background: 240 6% 12%;
    --container-background: 240 6% 9%;

    --foreground: 0 0% 98%;

    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 65%;

    --card: 240 6% 12%;
    --card-foreground: 0 0% 98%;

    --popover: 240 6% 10%;
    --popover-foreground: 0 0% 98%;

    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;

    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;

    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;

    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;

    --ring: 240 4.9% 83.9%;

    --radius: 0.5rem;

    --chart-1:75 65% 49%;
    --chart-2: 75 66% 11%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;

    --sidebar-background: 240 6% 10%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 240 3.7% 15.9%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }

  .tabs-list[data-variant='underlined'] {
    @apply border-b border-secondary;
    position: relative;
  }

  .tabs-trigger[data-state='active'][data-variant='default'] {
    @apply bg-background text-foreground shadow-sm;
  }

  .tabs-trigger[data-variant='underlined'] {
    @apply text-muted-foreground;
    position: relative;
    margin-bottom: -1px; /* Align the bottom of the trigger with the bottom border */
  }

  .tabs-trigger[data-state='active'][data-variant='underlined'] {
    @apply text-primary font-semibold;
  }

  .tabs-trigger[data-variant='underlined']::after {
    @apply bg-secondary;
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    right: 0;
    height: 2px;
    transform: scaleX(0);
    transition: transform 0.2s ease-in-out;
  }

  .tabs-trigger[data-state='active'][data-variant='underlined']::after {
    @apply bg-secondary-foreground;
    transform: scaleX(1);
  }

  .hide-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .hide-scrollbar::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: 'rlig' 1, 'calt' 1;
  }
}
